<template>
  <section
    class="w-4/5 md:w-2/3 mx-auto bg-white text-c_lightblue shadow-xl rounded-sm"
  >
    <header-coil :title="title" :urlEN="urlEN" />
    <article class="flex bg-gray-100">
      <div class="col m-10 bg-white w-full p-6">
        <img
          src="@/assets/images/coilaborando/2024/agosto/cover.jpg"
          class="img-fluid"
          alt=""
        />
        <div class="col text-center pt-10">
          <h1>COILaborando</h1>
          <h2 class="mb-6">Boletín Informativo</h2>
          <p>A toda la comunidad acádemica de la UNAM</p>
          <p>
            Se le informa a la comunidad acádemica de la Universidad Nacional
            Autónoma de México, (UNAM) que ya se encuentra abierto el programa
            de Aprendizaje Colaborativo Internacional en Línea (COIL).
          </p>
        </div>
        <newsletter-view />
        <p class="mt-6 text-center">
          <router-link
            :to="{ name: 'previos' }"
            class="bg-c_darkmagenta hover:bg-c_lightmagenta p-2 rounded text-gray-50"
          >
            ver anteriores
          </router-link>
        </p>
      </div>
    </article>
  </section>
</template>

<style></style>

<script>
import HeaderCoil from "@/components/HeaderCoil.vue";
import NewsletterView from "@/views/coilaborando/2024/OctubreView.vue";

export default {
  name: "CoilaborandoView",
  components: {
    HeaderCoil,
    NewsletterView,
  },
  data() {
    return {
      title: "",
      urlEN: "https://coil.unam.mx/#/coilaborando",
    };
  },
};
</script>
