<template>
  <section>
    <header class="mb-10">
      <div class="md:flex md:flex-row text-center md:text-right p-2">
        <div class="self-center basis-1/3 md:text-left text-center">
          <router-link :to="{ name: 'home' }" class="inline-block">
            <img
              src="@/assets/images/coil-header.png"
              alt="COIL UNAM"
              width="120"
            />
          </router-link>
        </div>
        <div class="self-center basis-2/3">
          <h2 class="text-c_lightblue">Comisión COIL</h2>
        </div>
      </div>
      <div class="bg-gray-100 p-1 text-right">
        <a href="https://coil.unam.mx/en/#/comission" class="inline-block">
          <img src="@/assets/images/flags/us.png" width="24" class="mr-2" />
        </a>
      </div>
    </header>
    <comission-info />
  </section>
</template>

<script>
import ComissionInfo from "@/components/ComissionInfo.vue";

export default {
  name: "ComissionView",
  components: {
    ComissionInfo,
  },
  data() {
    return {
      title: "Comisión COIL",
    };
  },
};
</script>
